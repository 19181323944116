import './index.css'

import { createRef, useEffect, useState } from 'react';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import InnerHTML from 'dangerously-set-html-content'

import RestHandler from '../../rest/RestHandler';
import WithConsumer, { IConsumerProps } from '../../wrappers/WithConsumer';

type TSidebarItem = {
    id: string,
    id_base: string,
    rendered: string,
    sidebar: string,
    nodeRef: React.MutableRefObject<any>
};

type TSidebar = Array<TSidebarItem>;

interface ISidebadProps extends IConsumerProps {
    sidebarId?: string,
    background?: boolean
};

const Sidebar = ({ context, sidebarId, background }: ISidebadProps): JSX.Element => {
    const [sidebar, setSidebar] = useState<TSidebar>([]);

    useEffect(() => {
        context.addLoading();
        let url = '/wp-json/wp/v2/widgets?sidebar=' + sidebarId;
        RestHandler.get(url).then((response) => {
            let newSidebar: TSidebar = [];
            response.data.forEach((sidebarItem: TSidebarItem) => {
                let newSidebarItem = sidebarItem;
                newSidebarItem.nodeRef = createRef();
                newSidebar.push(newSidebarItem);
            })

            setSidebar(newSidebar);
        }).catch(() => {
        }).finally(() => {
            context.removeLoading();
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [sidebarId])

    return (
        <TransitionGroup className="container-vertical">
            {
                sidebar.map((item, i) => {
                    return (
                        <CSSTransition
                            classNames="fader"
                            nodeRef={item.nodeRef}
                            mountOnEnter={true}
                            unmountOnExit={true}
                            appear={true}
                            timeout={10000}
                            addEndListener={(done: () => void) => {
                                item.nodeRef.current?.addEventListener("transitionend", done, false);
                            }}
                            key={sidebarId + "_" + item.id}
                        >
                            <div
                                ref={item.nodeRef}
                                className={"sidebar-item" + (background ? " container-style" : "-no-background")}
                            >
                                <InnerHTML html={item.rendered} />
                            </div>
                        </CSSTransition>
                    )
                })
            }
        </TransitionGroup>

    );
};

export default WithConsumer(Sidebar);