import * as React from 'react';

import type { RouteMatch } from 'react-router-dom';
import RestHandler from '../rest/RestHandler';

export type TMenuItem = {
    url: string,
    title: string,
    ID: string,
    menu_item_parent: string,
    children: Array<TMenuItem>
};
export type TMenu = Array<TMenuItem>;
export type TMenus = Record<string, TMenu>;

export interface TComic {
    archivePage: string,
    comicSlug: string,
    name: string,
    firstPage: string,
    lastPage: string
};

export interface IProps {
    contextType: string,
    router: RouteMatch | null,
    children?: React.ReactNode,
    index?: number,
    duration?: number,
};

export type IBackgroundImageRecord = Record<string, string>;

export type IBackgroundImages = Record<string, IBackgroundImageRecord>;

export interface IContextState {
    loading: boolean,
    loadingCount: number,
    addLoading: Function,
    removeLoading: Function,
    contextType: string,
    term: string,
    slug: string,
    route: string | undefined,
    menus: TMenus,
    comics: Record<string, TComic>,
    castPageSlug: string,
    logoImages: Record<string, string>,
    backgroundImages: IBackgroundImages,
    currentComic: {
        comicPageFullSlug: string,
        comicPageSlug: string,
        comicSlug: string,
        locationSlug: string,
    },
    setComicLocation: Function,
    mediumScreenWidth: number
};

export const StoreContext = React.createContext<Readonly<IContextState>>(
    {
        loading: false,
        loadingCount: 0,
        addLoading: () => { },
        removeLoading: () => { },
        contextType: '',
        term: '',
        slug: '',
        route: '',
        menus: {},
        comics: {},
        castPageSlug: '',
        logoImages: {},
        backgroundImages: {},
        currentComic: {
            comicPageFullSlug: '',
            comicPageSlug: '',
            comicSlug: '',
            locationSlug: ''
        },
        setComicLocation: () => { },
        mediumScreenWidth: 1025,
    }
);
export const Consumer = StoreContext.Consumer;


export class Provider extends React.Component<IProps, IContextState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: false,
            loadingCount: 0,
            addLoading: this.addLoading.bind(this),
            removeLoading: this.removeLoading.bind(this),
            contextType: props.contextType,
            term: props.router ? (props.router.params.term ? props.router.params.term : '') : '',
            slug: props.router ? (props.router.params.slug ? props.router.params.slug : '') : '',
            route: props.router ? props.router.route.path : '',
            menus: {},
            comics: {},
            castPageSlug: '',
            logoImages: {},
            backgroundImages: {},
            currentComic: {
                comicPageFullSlug: props.router ? (props.router.params['*'] ? props.router.params['*'] : '') : '',
                comicPageSlug: props.router ? (props.router.params['*'] ? this.formatComicSlug(props.router.params['*']) : '') : '',
                comicSlug: props.router ? (props.router.params['*'] ? this.getComicSlugFromRouter(props.router.params['*']) : '') : '',
                locationSlug: ''
            },
            setComicLocation: this.setComicLocation.bind(this),
            mediumScreenWidth: 1025,
        };
    }

    addLoading() {
        this.setState((prevState: IContextState) => {
            return {
                loadingCount: prevState.loadingCount + 1,
                loading: true
            }
        })
    }

    removeLoading() {
        this.setState((prevState: IContextState) => {
            let newLoadingCount = prevState.loadingCount - 1;
            return {
                loadingCount: newLoadingCount,
                loading: (newLoadingCount > 0)
            }
        })
    }

    componentDidMount() {
        this.getMenus();
        this.getComics();
        this.getCastPageSlug();
        this.getLogos();
        this.getBackgrounds();
    }

    componentDidUpdate(prevProps: any) {
        if (!this.props.router) {
            return;
        }

        if (prevProps.router.pathname === this.props.router.pathname) {
            return;
        }

        if (!this.props.router.route.path) {
            return;
        }

        this.setState((prevState: IContextState) => {
            return {
                contextType: this.props.contextType,
                term: this.props.router?.params.term ? this.props.router.params.term : '',
                slug: this.props.router?.params.slug ? this.props.router.params.slug : '',
                route: this.props.router?.route.path,
                currentComic: {
                    comicPageFullSlug: this.props.router?.params['*'] ? this.props.router.params['*'] : '',
                    comicPageSlug: this.props.router?.params['*'] ? this.formatComicSlug(this.props.router.params['*']) : '',
                    comicSlug: this.props.router?.params['*'] ? this.getComicSlugFromRouter(this.props.router.params['*']) : '',
                    locationSlug: this.props.router?.params['*'] ? prevState.currentComic.locationSlug : ''
                }
            }
        });
    }

    getComics() {
        let url = '/wp-json/comics/v1/comics';
        let self = this;

        return RestHandler.get(url).then((response) => {
            self.setState({
                comics: response.data
            })
        }).catch(() => {
            self.setState({
                comics: {},
            })
        });
    }

    getMenus() {
        let url = '/wp-json/generic/v1/menu/';
        let self = this;

        return RestHandler.get(url).then((response) => {
            let processedMenus: TMenus = {};
            let responseMenus: TMenus = response.data;

            Object.keys(responseMenus).forEach(menu => {
                let menuItems = responseMenus[menu];

                let parentItems = menuItems.filter(menuItem => {
                    return menuItem['menu_item_parent'] === "0";
                })

                parentItems.forEach(parentItem => {
                    parentItem['children'] = [];
                });

                menuItems.filter(menuItem => {
                    return menuItem['menu_item_parent'] !== "0";
                }).forEach(childItem => {
                    parentItems.filter(parentItem => {
                        return parseInt(parentItem['ID']) === parseInt(childItem['menu_item_parent']);
                    }).forEach(parentItem => {
                        parentItem['children'].push(childItem);
                    })
                });

                processedMenus[menu] = parentItems;

            });
            self.setState({
                menus: processedMenus,
            });
        }).catch(() => {
            self.setState({
                menus: {}
            })
        });
    }

    getCastPageSlug() {
        let url = '/wp-json/settings/v1/cast_page';
        let self = this;

        return RestHandler.get(url).then((response) => {
            self.setState({
                castPageSlug: response.data
            })
        }).catch(() => {
            self.setState({
                castPageSlug: '',
            })
        });
    }

    getLogos() {
        let url = '/wp-json/settings/v1/logos';
        let self = this;

        return RestHandler.get(url).then((response) => {
            self.setState({
                logoImages: response.data
            })
        }).catch(() => {
            self.setState({
                logoImages: {},
            })
        });
    }

    getBackgrounds() {
        let url = '/wp-json/settings/v1/backgrounds';
        let self = this;

        return RestHandler.get(url).then((response) => {
            self.setState({
                backgroundImages: response.data
            })
        }).catch(() => {
            self.setState({
                backgroundImages: {},
            })
        });
    }

    formatComicSlug(longSlug: string): string {
        return longSlug.split('/').reverse()[1];
    }

    getComicSlugFromRouter(longSlug: string): string {
        return longSlug.split('/')[0];
    }

    setComicLocation(locationSlug: string) {
        this.setState((prevState: IContextState) => {
            return {
                currentComic: {
                    ...prevState.currentComic,
                    locationSlug: locationSlug
                }
            }
        })
    }

    render() {
        return (
            <>
                <StoreContext.Provider value={this.state}>
                    {this.props.children}
                </StoreContext.Provider>
            </>
        );
    }
}